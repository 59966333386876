export default [
  {
    label: 'breadcrumb.accountInfomration',
    icon: 'LockIcon',
    type: 'divider',
  },
  {
    key: 'userName',
    label: 'field.userName',
    rules: 'required',
    type: 'text',
    cols: 12,
  },
  {
    key: 'phoneNumber',
    label: 'field.phoneNumber',
    rules: 'required',
    type: 'text',
    cols: 12,
  },
  {
    key: 'telegramId',
    label: 'Telegram ID',
    type: 'text',
    cols: 12,
  },

  {
    key: 'password',
    name: 'new-password',
    label: 'field.password',
    rules: 'required|min:6',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'retypePassword',
    label: 'field.retypePassword',
    rules: 'required|confirmed:password',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'isEnable',
    label: 'status.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
